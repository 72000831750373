// Scroll to top button

$btn-scroll-top-size: 3rem;
$btn-scroll-top-transition: right 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),
  opacity 0.3s, background-color 0.25s ease-in-out !default;

.btn-scroll-top {
  display: block;
  position: fixed;
  right: -($btn-scroll-top-size * 1.5);
  bottom: $spacer * 1.25;
  width: $btn-scroll-top-size;
  height: $btn-scroll-top-size;
  line-height: $btn-scroll-top-size;
  transition: $btn-scroll-top-transition;
  border-radius: $border-radius-xxl;
  background-color: $primary;
  color: $white;
  text-align: center;
  opacity: 0;
  z-index: 99;

  .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }

  &:hover {
    background-color: $primary;
    color: $white;
    opacity: 0.8 !important;
    .btn-scroll-top-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show {
    right: $spacer * 1.25;
    opacity: 0.5;
  }

  @include media-breakpoint-down(sm) {
    width: $btn-scroll-top-size * 0.833;
    height: $btn-scroll-top-size * 0.833;
    bottom: $spacer;
    > .btn-scroll-top-icon {
      line-height: $btn-scroll-top-size * 0.833;
    }
    &.show {
      right: $spacer;
    }
  }
}
