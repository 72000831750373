.breaking-news-warpper {
  border: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    display: flex;
    padding: 0.8rem 1rem;
    background-color: $primary;
    color: $white;
    font-weight: bold;
    flex-shrink: 0;
    margin-right: 0.3rem;
    border-radius: $border-radius-lg $border-radius-sm $border-radius-sm
      $border-radius-lg;
  }

  .breaking-news-slider-warpper {
    padding: 0.8rem 1rem;
    overflow: hidden;
    display: flex;
    flex-grow: 1;
    border-radius: $border-radius-sm $border-radius-lg $border-radius-lg
      $border-radius-sm;

    .swiper-slide {
      white-space: nowrap;
      overflow: hidden;
      a {
        color: $primary !important;
      }
    }

    .swiper-pagination-bullet {
      background: rgba($color: $primary, $alpha: 0.5);
    }
    .swiper-pagination-bullet-active {
      background: $primary;
    }
  }
}
