.music-item,
.post-item--08,
.post-item--07,
.post-item--06,
.post-item--05,
.post-item--04,
.post-item--02,
.post-item--01 {
  background-color: $white;
  border-radius: $border-radius-xl;
  position: relative;
  overflow: hidden;

  a {
    transition: all 0.3s;
    margin: 0.5rem;
    display: block;

    &:hover {
      .image-holder {
        border-radius: $border-radius-xl $border-radius-xxl $border-radius-xl
          $border-radius-xxl;
      }
      .title-warrper {
        .title {
          text-decoration-color: rgba(0, 0, 0, 1);
        }
      }
    }

    .image-holder {
      border-radius: $border-radius-xl;
      overflow: hidden;
      transition: all 0.5s;
    }

    .title-warrper {
      padding: 0.7rem;
      &.title-lg {
        .title {
          font-size: 1.3rem;
        }
      }

      .title {
        text-decoration: underline 0.1rem rgba(0, 0, 0, 0);
        transition: text-decoration-color 0.3s;
        font-size: 1.1rem;
        color: $black;
        font-weight: bold;
      }

      .subtitle {
        color: mix($black, $white, 45%);
        margin-bottom: 0;
      }
    }
  }
}

.post-item--08,
.post-item--07,
.post-item--02 {
  a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .image-holder {
      width: 170px;
      height: 170px;
      flex-shrink: 0;
    }

    .title-warrper {
      padding: 0 0.7rem;
      .title {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 1.25rem;
  }
}

.post-item--03 {
  position: relative;

  a {
    border-radius: $border-radius-xl;
    overflow: hidden;
    display: block;
    transition: all 0.5s;
    position: relative;
    margin-bottom: 1.5rem;

    .title-warrper {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin-bottom: 1rem;

      &.title-lg {
        .title {
          font-size: 1.2rem;
        }
      }

      &.title-xl {
        .title {
          font-size: 1.8rem;
        }
        .subtitle {
          font-size: 1.3rem;
        }
      }

      .title {
        color: $white;
        font-weight: bold;
        clear: both;

        p {
          margin: 0;
        }
      }

      .tag {
        color: rgba($color: $white, $alpha: 0.7);
        font-size: 0.9rem;
      }

      .subtitle {
        color: mix($black, $white, 45%);
      }
    }
  }

  .avatar {
    position: absolute;
    width: 4rem;
    height: 4rem;
    z-index: 1;
    top: -1.5rem;
    right: 1rem /*rtl:ignore*/;
    border-radius: $border-radius;
    overflow: hidden;
    border: 4px solid $white;
  }
}

.post-item--04 {
  a {
    &:hover {
      .title {
        p {
          color: $black;
        }
      }
    }
  }
  .title {
    font-weight: bold;
    p {
      margin: 0;
      color: mix($black, $white, 65%);
    }
  }
}

.post-item--06,
.post-item--05 {
  overflow: visible !important;
  background-color: $gray-200;
  height: calc(100% - 2rem);
  margin-top: 1rem;
  .image-holder {
    margin-bottom: -1rem;
    transform: translateY(-1rem);
  }

  .title {
    color: $black;
    font-size: 1.3rem;
    font-weight: bold;

    p {
      margin: 0;
    }
  }

  .subtitle {
    color: mix($black, $white, 45%);
  }
}

.post-item--06 {
  background-color: $white;

  .title-warrper {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    margin-bottom: 1rem;

    .title {
      color: $white;
      font-weight: bold;
      clear: both;

      p {
        margin: 0;
      }
    }
  }

  .subtitle-title-warrper {
    padding: 1.2em 0.7rem !important;
    color: $black;

    .author-title {
      font-weight: bold;
      font-size: 1.2rem;
    }

    .date {
      font-size: 0.85rem;
      color: mix($black, $white, 65%);
    }
  }
}

.post-item--07 {
  a {
    align-items: stretch;

    .image-holder {
      width: 16rem !important;
      height: 16rem !important;
    }

    .title-warrper {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .title {
        font-size: 1.5rem;
      }
    }
  }
}

.post-item--08 {
  padding-bottom: 0.75rem;
  border-radius: 0;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  &:not(:last-child) {
    margin-bottom: 0.75rem;
    border-bottom: 1px solid $gray-200;
  }

  &:last-child {
    border-radius: $border-radius-xl;
  }

  a {
    .image-holder {
      width: 5rem !important;
      height: 5rem !important;
    }

    .title-warrper {
      min-width: 0;
      p {
        margin: 0;
      }
    }
  }
}

.book-item {
  .book-container {
    position: relative;

    .image-shadow {
      position: absolute;
      top: -15rem;
      left: calc(50% - 7rem);
      width: 14rem;
      transition: all 0.3s;

      &::after {
        content: "";
        position: absolute;
        bottom: -0.5rem;
        left: 2rem;
        right: 2rem;
        height: 17px;
        border-radius: 50%;
        background-color: #00000040;
        filter: blur(5px);
        transition: all 0.3s;
      }
    }
    .image-holder {
      border-radius: $border-radius-xl;
      z-index: 1;
    }
  }

  .title-warrper {
    border-radius: $border-radius-xl;
    background-color: $gray-200;
    margin-top: 18rem;
    padding: 6rem 1rem 1rem 1rem;
    text-align: center;
    height: 11rem;
    .title {
      color: $black;
      font-size: 1.3rem;
      font-weight: bold;
    }

    .subtitle {
      color: mix($black, $white, 45%);
    }
  }

  &:hover {
    .image-shadow {
      margin-top: -3rem;

      &::after {
        bottom: -3.3rem;
        left: 1rem;
        right: 1rem;
        filter: blur(9px);
        background-color: #00000029;
      }
    }
  }
}

.music-item {
  a {
    margin: 0;
    .title-warrper {
      padding: 0.7rem 0;
      .title {
        font-weight: bold;
      }
    }
  }
}

.programs-swiper,
.news-swiper {
  position: relative;

  &::before,
  &::after {
    content: "";
    z-index: 2;
    position: absolute;
    width: 10rem;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    bottom: 1rem;
    transition: opacity 0.6s;
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: scale(-1);
  }

  &.hide-start {
    &::before {
      opacity: 0;
    }
  }

  &.hide-end {
    &::after {
      opacity: 0;
    }
  }

  .swiper-slide {
    width: 30rem;

    &:first-child {
      width: 46.5rem;
    }
  }
}

.programs-swiper {
  .swiper-slide {
    width: 25rem;

    &:first-child {
      width: 25rem;
    }
  }
}

.side-news {
  background-color: $white;
  border-radius: $border-radius-xl;
}

.post-item--stacked-cards {
  position: relative;
  margin-top: 2rem;
  will-change: transform;

  &:hover {
    &::before {
      transform: translateY(0) scale(0.9);
    }
    &::after {
      transform: translateY(0) scale(0.95);
    }
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 12rem;
    background-position: center;
    background-size: cover;
    background-image: var(--bg-image);
    border-radius: $border-radius-xl;
    overflow: hidden;
    will-change: transform;
  }

  &::after {
    transform: translateY(-1rem) scale(0.95);
    z-index: -1;
    opacity: 0.6;
    transition: all 0.3s cubic-bezier(0.2, 0.78, 0.29, 1);
  }

  &::before {
    transform: translateY(-2rem) scale(0.9);
    z-index: -2;
    opacity: 0.3;
    transition: all 0.4s cubic-bezier(0.2, 0.78, 0.29, 1);
  }
}
