// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
// @include media-breakpoint-up(sm) {}
// @include media-breakpoint-up(md) {}
// @include media-breakpoint-up(lg) {}
// @include media-breakpoint-up(xl) {  }
// @include media-breakpoint-up(xxl) {  }

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) {  }`
// Example: Style from medium breakpoint and down
@include media-breakpoint-down(lg) {
  .news-swiper {
    .swiper-slide {
      width: 100%;
      &:first-child {
        width: 100%;

        .ratio-8x3 {
          --bs-aspect-ratio: 71.1538461538%;
        }
      }
    }
  }
}
//  @include media-breakpoint-down(md) {}
//  @include media-breakpoint-down(sm) {}
// @include media-breakpoint-down(xl) {  }
// @include media-breakpoint-down(xxl) {  }
