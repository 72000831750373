::selection {
  color: $white;
  background: $primary;
}

body {
  background-color: $gray-200;
}

.ratio {
  background-color: $gray-300;
  overflow: hidden;

  img {
    object-fit: cover;
  }
}

a {
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    .image-holder {
      img {
        transform: scale(1.05);
      }
    }
    .image-overlay {
      &.linear-bg {
        &::before {
          top: 40%;
        }
      }
      &:not(.linear-bg) {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .centerd-icon {
        color: $primary;
      }
    }
  }
}

hr {
  background-color: $gray-400;
}

.image-holder {
  position: relative;
  overflow: hidden;

  img {
    transition: all 0.3s;
  }

  .image-overlay {
    &.linear-bg {
      &::before {
        background-image: linear-gradient(180deg, transparent, black);
        top: 50%;
      }
    }
    transition: all 0.3s;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    &:not(.linear-bg) {
      background-color: rgba(0, 0, 0, 0.35);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    .centerd-icon {
      color: $white;
      font-size: 5rem;
      transition: all 0.5s;
      z-index: 20;
      position: absolute;
      left: 50% /*rtl:ignore*/;
      top: 50%;
      transform: translate(-50%, -50%);
      // margin-right: -2rem;
      // margin-top: -2rem;
    }
  }
}

.text-truncate-container {
  //  width: 250px;

  .text-truncate-element {
    -webkit-line-clamp: 4;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .text-truncate-element-2 {
    -webkit-line-clamp: 2 !important;
  }

  .text-truncate-element-3 {
    -webkit-line-clamp: 3 !important;
  }
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    background-image: url("data:image/svg+xml,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='%23F9FAFC' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.72,15.78a.75.75,0,0,1-.53.22h-.38a.77.77,0,0,1-.53-.22L6.15,10.64a.5.5,0,0,1,0-.71l.71-.71a.49.49,0,0,1,.7,0L12,13.67l4.44-4.45a.5.5,0,0,1,.71,0l.7.71a.5.5,0,0,1,0,.71Z'/%3e%3c/svg%3e");
    transition: all 0.2s ease-in-out;
  }

  .icon-arrow-down {
    transition: all 0.2s;
  }

  &.show {
    .icon-arrow-down {
      transform: rotate(180deg);
    }
  }
}

.dropdown-menu {
  box-shadow: 0 0.6125rem 2.5rem 0.6125rem rgb(140 152 164 / 18%);
  .dropdown-item {
    border-radius: $border-radius;
  }
}

.marquee {
  overflow: hidden;
  position: relative;
  width: 100%;

  a,
  span {
    white-space: nowrap;
    position: relative;
    will-change: transform;
    float: right;
    animation: marquee 18s linear 0s infinite;
  }

  &:hover {
    animation-play-state: paused;
  }
}

@keyframes marquee {
  0% {
    right: 0%;
    transform: translateX(100%);
  }
  95%,
  100% {
    right: 100%;
    transform: translateX(0%);
  }
}

.widget {
  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .title {
      font-size: 2rem;
      font-weight: bolder;
    }

    &:hover {
      .title-border {
        width: 60px;
        left: 25px;
        &::after,
        &::before {
          width: 12px;
        }
      }
    }

    .title-border {
      width: 70px;
      height: 3px;
      background-color: $red;
      position: absolute;
      bottom: 0;
      left: 20px;
      border-radius: 4px;
      transition: all 0.1s;
      box-shadow: 0px 0px 7px 6px rgba($color: $primary, $alpha: 0.1);

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        height: 3px;
        background-color: $red;
        opacity: 0.45;
        width: 10px;
        border-radius: 4px;
        transition: all 0.1s;
      }

      &::before {
        left: -15px;
      }
      &::after {
        right: -15px;
      }
    }
  }
  .widget-body {
    margin-top: 2rem;
  }
}

.breaking-news-slider-warpper,
.swiper {
  .swiper-pagination {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    bottom: 0;
    padding: 0rem 1rem;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    transition: all 0.3s;
    background: rgba($color: $primary, $alpha: 0.5);
  }
  .swiper-pagination-bullet-active {
    background: $primary;
    width: 30px;
  }
}

.swiper-custom-nav {
  --swiper-navigation-size: 1.2rem;

  background-color: $white;
  color: $black;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;

  &.swiper-button-prev {
    padding-right: 5px;
    left: 20px;
  }

  &.swiper-button-next {
    padding-left: 5px;
    right: 20px;
  }
}

.button-play {
  display: inline-block;
  position: relative;

  &.is-play {
    background-color: rgba($color: $white, $alpha: 0.5);
    border-radius: 50%;
    width: 100px;
    height: 100px;

    .button-outer-circle {
      background: rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
    }

    .button-icon {
      position: absolute;
      left: 50% /*rtl:ignore*/;
      top: 50%;
      display: grid;
      align-items: center;
      width: 30px;
      height: 30px;
      transform: translate(-50%, -50%);

      svg {
        fill: $primary;

        path {
          stroke: $primary;
        }
      }
    }

    .button-icon .triangle {
      animation: fadeIn 7s ease;
    }

    .button-icon .path {
      stroke-dasharray: 90;
      stroke-dashoffset: 0;
      animation: triangleStroke 3s;
      animation-timing-function: ease;
    }
  }

  &.sm {
    width: 60px;
    height: 60px;
  }

  .has-scale-animation {
    animation: smallScale 3s infinite;
  }

  .has-delay-short {
    animation-delay: 0.5s;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes triangleStroke {
  from {
    stroke-dashoffset: 90;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes triangleStroke {
  from {
    stroke-dashoffset: 90;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes smallScale {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}
@keyframes smallScale {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0;
  }
}

.simplebar-scrollbar::before {
  background-color: $primary;
}

.btn-light-primary {
  color: $black;
  background-color: $gray-200;
  border: none;

  &:active,
  &:hover {
    color: $primary;
    background-color: rgba($color: $primary, $alpha: 0.05);
  }
}

.btn-light-secondary {
  color: $secondary;
  background-color: rgba($color: $secondary, $alpha: 0.05);
  border: none;

  &:active,
  &:hover {
    color: $secondary;
    background-color: rgba($color: $secondary, $alpha: 0.1);
  }
}

.btn-light-gray {
  color: $white;
  background-color: rgba($color: $white, $alpha: 0.1);
  border: none;

  &:active,
  &:hover {
    color: $white;
    background-color: rgba($color: $white, $alpha: 0.2);
  }
}

.btn-light-outline-gray {
  color: $black;
  background-color: transparent;
  border: 1px solid $gray-300;

  &:active,
  &:hover {
    color: $white;
    background-color: $black;
    border-color: $black;
  }
}

.icon-badge {
  background-color: $secondary;
  color: $white;
  border-radius: $border-radius;
  padding: 0.5rem;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.pagination-borderd {
  border-radius: $border-radius-lg;
  border: 1px solid $gray-200;
  padding: 0.4rem;

  .page-item {
    border-radius: $border-radius-lg;
    overflow: hidden;
    margin: 0 0.2rem;
  }
}

.content {
  font-size: 120%;
}
.card,
.white-rounded-bg {
  background-color: $white;
  border-radius: $border-radius-xl;
}

.card-body {
  padding: 1rem;
}

.white-rounded-bg {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.fill-background {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: $border-radius-xl;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.custom-input {
  background-color: $gray-200;
  border-radius: $border-radius-lg;
  position: relative;
  .icon {
    position: absolute;
    left: 0.2rem;
    padding: 0.7rem 0.4rem;
    color: $gray-500;
  }
  .form-control {
    color: $gray-500;
    background: transparent;
    border: none;
    padding: 0.5rem 0.5rem 0.5rem 2.2rem;
    &::placeholder {
      color: $gray-500;
    }
  }
}
