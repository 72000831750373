//ُSpacing
$enable-negative-margins: true !default;

//navbar
$navbar-dark-color: mix($white, $secondary, 50);
$navbar-dark-hover-color: $primary;
$navbar-light-hover-color: $primary;

//dropdowns
$enable-caret: false !default;
$dropdown-padding-x: 0.5rem !default;
$dropdown-border-width: 0 !default;
$dropdown-item-padding-y: 0.35rem !default;
$dropdown-link-active-color: none !default;
$dropdown-link-active-bg: none !default;

//tooltips
$tooltip-font-size: 0.8rem;
$tooltip-max-width: 200px;
$tooltip-color: $orange;
$tooltip-bg: $white;
$tooltip-opacity: 1;

$tooltip-arrow-width: 1rem;
$tooltip-arrow-height: 0.5rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1300px,
);

// scss-docs-start border-radius-variables
$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.4rem !default;
$border-radius-xl: 0.7rem !default;
$border-radius-xxl: 1.5rem !default;
$border-radius-pill: 50rem !default;
// scss-docs-end border-radius-variables

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 4,
  // 7: $spacer * 5,
  // 8: $spacer * 6,
  // 9: $spacer * 7,
  // 10: $spacer * 8,,,,,,,,,,,,,,,,,,,,
);

$aspect-ratios: (
  "1x1": 100%,
  "4x3": calc(3 / 4 * 100%),
  "16x9": calc(9 / 16 * 100%),
  "16x3": calc(8.128 / 16 * 100%),
  "95x52": calc(52 / 95 * 100%),
  "22x30": calc(30 / 22 * 100%),
  "5x3": calc(37 / 52 * 100%),
  "8x3": calc(37 / 81 * 100%),
  // "29x18": calc(184 / 292 * 100%),
  // "21x9": calc(9 / 21 * 100%),,,,,,,,,,,,,,,,,
);

$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: 0.85rem,
  8: 0.7rem,
) !default;

// scss-docs-start breadcrumb-variables
// $breadcrumb-font-size: null !default;
// $breadcrumb-padding-y: 1rem !default;
// $breadcrumb-padding-x: 0 !default;
// $breadcrumb-item-padding-x: 0.5rem !default;
// $breadcrumb-margin-bottom: 0rem !default;
// $breadcrumb-bg: null !default;
// $breadcrumb-divider-color: $gray-600 !default;
// $breadcrumb-active-color: $gray-600 !default;
// $breadcrumb-divider: quote("/") !default;
// $breadcrumb-divider-flipped: $breadcrumb-divider !default;
// $breadcrumb-border-radius: null !default;
// scss-docs-end breadcrumb-variables

// forms
$form-check-input-border-radius: 0 !default;

$input-focus-box-shadow: 0 !default;

$input-border-radius: 0 !default;
$input-border-radius-sm: 0 !default;
$input-border-radius-lg: 0 !default;

// btns
$btn-focus-width: 0 !default;
$btn-focus-box-shadow: 0 !default;
$btn-border-radius: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;

// alert
$alert-color-scale: -100% !default;
$alert-border-scale: -70% !default;
$alert-bg-scale: -90% !default;
$alert-border-radius: $border-radius-lg;

$nav-link-color: $secondary !default;
$nav-link-hover-color: $primary !default;

//pagingation
$pagination-border-width: 0 !default;
$pagination-padding-y: 0.575rem !default;
$pagination-padding-x: 1rem !default;
$pagination-border-radius: $border-radius-lg !default;
$pagination-focus-box-shadow: 0 !default;
