.content-warper {
  border-radius: $border-radius-xl;
  background-color: $white;
  padding: 3rem 2rem;
}

.share {
  ul {
    list-style: none;
    padding: 0;
    display: flex;
    margin: 0rem -0.5rem;
  }

  .share-icon {
    margin: 0 0.5rem;

    &.small {
      margin: 0 0.2rem;
      a {
        width: 2.5rem;
        height: 2.5rem;
        i {
          line-height: 2.4rem !important;
        }
      }
    }

    a {
      display: block;
      border: 1px solid rgba($color: $black, $alpha: 0.1);
      border-radius: $border-radius-xl;
      width: 3rem;
      height: 3rem;
      transition: all 0.2s;
      color: $black;

      &:hover {
        color: $white;
        background-color: $black;
      }

      i {
        line-height: 2.9rem !important;
        display: block;
        margin: auto;
      }
    }
  }
}

.comment {
  .avatar {
    border-bottom-left-radius: 0.2rem;
    width: 60px;
    height: 60px;
  }

  .title {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .date {
    font-size: 0.8rem;
    color: rgba($color: $black, $alpha: 0.65);
  }

  .body {
    margin-top: 0.5rem;
    background-color: $gray-100;
    padding: 1rem;
    border-radius: $border-radius-lg;
    color: rgba($color: $black, $alpha: 0.65);
  }
}
