$blue: #1377fe;
$blue-dark: #0c2240;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #8c0000;
$orange: #fd7e14;
$yellow: #ffa800 !default;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #f6f6f6;
$gray-300: #e5e5e5;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #8e8e90;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;

$primary: $red !default;
$secondary: $blue !default;
$success: $green;
$info: $cyan;
$warning: $orange;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger": $danger,
  "light": $light,
  "orange": $orange,
  "dark":       $dark,
);

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  // "500": $gray-500,
  // "600": $gray-600,
  // "700": $gray-700,
  // "800": $gray-800,
  // "900": $gray-900,,,,,,,,,
);

$body-color: $black !default;
// $min-contrast-ratio: 1.5 !default;
