header {
  position: relative;
  z-index: 1030;

  .top-header {
    .container > .row {
      height: 60px;
    }

    .languages {
      display: flex;
      list-style: none;
      font-size: 0.8rem;
      margin: 0;
      padding: 0;

      .lang-item {
        .lang-link {
          margin-right: 0.8rem;
          color: $gray-600;
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 12px;
            height: 2px;
            border-radius: 2px;
            bottom: -0.3rem;
            left: 50%;
            background: $red;
            transition: all 0.25s ease;
            transform: translateX(-50%) scaleX(0);
          }

          &.active,
          &:active,
          &:hover {
            color: $black;
            &::before {
              transform: translateX(-50%) scaleX(1);
            }
          }
        }
      }
    }

    .social-icons {
      margin-right: -0.5rem;

      .nav-item {
        position: relative;

        &::before {
          content: "";
          background: $gray-400;
          height: 0.5rem;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(1rem);
          transition: all 0.25s ease;
          width: 1px;
        }

        &:last-child {
          &::before {
            content: none;
          }
        }

        .nav-link {
          color: $black;
          transition: 0.3s;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  .weather {
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    background-color: $white;
    padding: 0.1rem 0.7rem;
    border-radius: 0.4rem;

    .title {
      font-weight: bold;
    }

    .date {
      margin-left: 0.3rem;
    }

    img {
      height: 2rem;
      margin-right: 0.2rem;
    }
  }

  .navbar {
    padding: 0.95rem 0;
    margin: 0 0.5rem;
    border-radius: $border-radius-xl $border-radius-xl 0 0;
    background-color: $white;
    transition: all 0.2s;
    z-index: 101;

    .navbar-nav {
      padding-left: 0 !important;
    }

    .navbar-brand {
      padding-right: 2rem;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        width: 1px;
        top: 0;
        height: 1.5rem;
        right: 0;
        background: rgba($white, 0.1);
        transition: all 0.25s ease;
        transform: translateY(1.2rem);
      }

      img {
        height: 2.2rem;
        box-shadow: 0px 10px 11px -5px rgba($primary, 0.2);
        transition: all 0.3s ease-in-out;
      }
    }

    .nav-item {
      text-transform: capitalize;

      .dropdown-toggle {
        justify-content: space-between;
      }

      &.dropdown {
        .dropdown-menu {
          background-color: $gray-100;

          .dropdown-item {
            padding: 0.65rem 1rem;
          }
        }
      }

      .nav-link {
        position: relative;
        padding: 0.5rem 1rem;
        margin: 0 0.2rem;
        transition: all 0.3s ease-in-out;

        &.dropdown-toggle {
          i {
            font-size: 0.9rem;
          }
        }
      }
    }
    &.fixed-top {
      box-shadow: 0px 1px 14px rgb(0 0 0 / 10%);
      margin: 0;
      border-radius: 0;

      &::after {
        height: 1px;
        pointer-events: none;
      }

      // .navbar-brand {
      //   img {
      //     height: 2rem;
      //     padding-right: 0.5rem;
      //   }
      // }

      .navbar-icons {
        .icon-radar {
          display: none;
        }
      }
    }
  }

  .navbar-search {
    background-color: $gray-200;
    border-radius: $border-radius-lg;
    position: relative;
    .search-icon {
      position: absolute;
      left: 0.2rem;
      padding: 0.7rem 0.4rem;
      color: $gray-500;
    }
    .search-input {
      color: $gray-500;
      background: transparent;
      border: none;
      padding: 0.5rem 0.5rem 0.5rem 2.2rem;
      &::placeholder {
        color: $gray-500;
      }
    }
  }

  .navbar-bottom {
    background-color: $white;
    border-radius: 0 0 $border-radius-xl $border-radius-xl;
    padding: 0.95rem 0;
    margin: 0 0.5rem;
  }
}

@include media-breakpoint-up(lg) {
  // navbar hover animation
  .navbar .dropdown-menu-end {
    left: 0;
    right: auto;
  }
  .navbar .nav-item .dropdown-menu {
    display: block;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
    margin-top: 0;
  }

  .navbar .dropdown-menu.fade-down {
    top: 80%;
    transform: rotateX(-75deg);
    transform-origin: 0% 0%;
  }
  .navbar .dropdown-menu.fade-up {
    top: 125%;
  }
  .navbar .nav-item:hover > .dropdown-menu {
    transition: 0.3s;
    opacity: 1;
    visibility: visible;
    top: 100%;
    transform: rotateX(0deg);
  }

  .dropdown-menu li {
    position: relative;
  }

  .nav-item .submenu {
    display: block;
    position: absolute;
    right: 100%;
    top: -7px;
  }

  .nav-item .submenu-right {
    left: 100%;
    right: auto;
  }

  .dropdown-menu > li:hover {
    background-color: #f1f1f1;
  }

  .dropdown-menu > li:hover > .submenu {
    visibility: visible;
    opacity: 1;
  }
  // end navbar hover animation
}

@include media-breakpoint-down(lg) {
  header {
    .navbar {
      .nav-item {
        .nav-link {
          line-height: 2rem;
        }
      }

      .navbar-nav {
        max-height: 22rem;
        overflow-y: scroll;
      }
    }

    .navbar-brand {
      padding: 0.6rem 0;
    }

    .dropdown-menu .dropdown-menu {
      margin-right: 0.7rem;
      margin-left: 0.7rem;
      margin-bottom: 0.5rem;
    }

    .nav-item {
      .dropdown-toggle {
        justify-content: start !important;
      }
    }
  }
}
