// @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,700;1,900&display=swap');
@font-face {
  font-family: "IRANYekan";
  src: url("../fonts/IRANYekanWebLight.woff2");
  font-weight: 300;
}

@font-face {
  font-family: "IRANYekan";
  src: url("../fonts/IRANYekanWebRegular.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "IRANYekan";
  src: url("../fonts/IRANYekanWebBold.woff2");
  font-weight: 600;
}

//fallback for english font text
@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter-ExtraBold.ttf");
  font-weight: 600;
}

$font-family-base: "Inter", "IRANYekan", sans-serif;
$font-family-code: "Inter", "IRANYekan", sans-serif;
$font-size-root: 16px !default;
