footer {
  margin-top: 17rem;
  background-color: $white;

  .footer-warpper {
    padding-top: 2rem;
    padding-bottom: 5rem;
  }

  .footer-top {
    .icon {
      box-shadow: 0px 10px 11px -5px rgba($color: $primary, $alpha: 0.2);
    }
  }

  .footer-mobile-app {
    margin-top: -7rem;
    background-color: $white;
    border-radius: $border-radius-xl;
    box-shadow: 0px 0px 0px 7px $gray-200;
    padding: 3.5rem 1.5rem;
    height: 100%;
    height: 23rem;

    .mobile-app {
      margin-top: -12rem;
      position: relative;
      transition: all 0.4s cubic-bezier(0, 0, 0.07, 1);
      z-index: 1;

      &::after {
        content: "";
        position: absolute;
        width: 300px;
        height: 7px;
        left: 5%;
        bottom: -2rem;
        background: #00000052;
        border-radius: 50%;
        filter: blur(6px);
        opacity: 0.35;
        transition: all 0.4s cubic-bezier(0, 0, 0.07, 1);
        transform: scaleY(1.4);
        transform-origin: bottom;
        z-index: -1;
      }
    }

    &:hover {
      .mobile-app {
        margin-top: -10rem;
        &::after {
          opacity: 1;
          bottom: -0.35rem;
          transform: scaleY(1);
        }
      }
    }
  }

  .footer-tags {
    margin: 1.5rem 0;
    .swiper-slide {
      width: auto;
    }
  }

  .footer-copyright {
    background-color: $black;
    color: $white;
    padding: 1.2rem 0;

    .links {
      margin-right: -1rem;
      .nav-item {
        position: relative;

        &::before {
          content: "";
          background: $gray-800;
          height: 0.5rem;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(1rem);
          transition: all 0.25s ease;
          width: 1px;
        }

        &:last-child {
          &::before {
            content: none;
          }
        }

        .nav-link {
          color: $gray-600;
          font-size: 0.7rem;
          transition: 0.3s;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  footer {
    margin-top: 5rem;
    .footer-mobile-app {
      margin-top: 18rem;
      height: 30rem;

      .mobile-app {
        margin-top: -18rem;
        margin-bottom: 4rem;
      }

      &:hover {
        .mobile-app {
          margin-top: -18rem;
          &::after {
            opacity: 0.35;
            bottom: -2rem;
          }
        }
      }
    }
  }
}
